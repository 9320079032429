module.exports=`登入
註冊
首頁
記錄
記錄詳情
借貸
客服
我的
貸款
貸款協議
關於我們
申請人簽名
提現
立即申請
使用者名稱
請填寫用戶名
手機號
請填寫您的手機號碼
密碼
請填寫密碼
驗證密碼
密碼不一致
邀請碼
請填寫邀請碼
忘記密碼
舊密碼
請填寫您的舊密碼
新密碼
請填寫您的新密碼
重複密碼
請重複密碼
忘記密碼請聯絡客服
登入密碼
提現密碼
返回首頁
返回登陸
熱門分類
熱門貸款
急速貸款
City rewerd oredit oard
聯絡電話
營業時間
每天上午
至晚上
聯絡客服
使用者帳號
關於我們
貸款協議
登出帳號
邀請碼
複製成功
確認
可提現金額
元
貸款記錄
退出
餘額
貸款分類
貸款列表
利率
提現帳戶
持卡人姓名
請填寫持卡人姓名
金融卡號
請輸入銀行卡號
提交
貸款金額
每月利息
貸款額度
分期付款
每期付款
貸款分期
申請貸款
貸款金額含利息
貸款提交期
貸款資訊
請在下面簽名
重置
登記
檢查
評價
提現成功
月
合約號
您的貸款已提交評估。 24小時內有專人抽空檢查評估，跟進詢問客服進度。
可提現金額
提現金額
提現密碼
請稍等
儲值
可用餘額
金融卡號
儲值金額
確認儲值
最高
最低
最大金額
最小金額
$
下一頁
申請貸款
期
逾期利率
分期
金額過小
金額過大
請選擇分期
更多
借款時間
還款時間
凍結餘額
個人資訊
交易密碼
重複交易密碼
交易密碼不一致
頭像不能為空
頭像
支付截圖
金融卡
暫無數據
帳戶類型
新增帳戶
銀行名稱
USDT地址
未審核
已通過
已拒絕
審核狀態
訂單編號
打款
未打款
已打款
還款
進行中
已完成
提前完成
逾期
正常
已逾期`