module.exports = {
  login: "登入",
  register: "註冊",
  home: "首頁",
  record: "記錄",
  recordDetails: "記錄詳情",
  order: "借貸",
  service: "客服",
  account: "我的",
  loan: "貸款",
  protocol: "貸款協議",
  about: "關於我們",
  sign: "申請人簽名",
  tixian: "提現",
  apply_now: "立即申請",
  username: "用戶名稱",
  username_tx: "請填寫用戶名",
  phone: "手機號",
  phone_tx: "請填寫您的手機號碼",
  mima: "密碼",
  mima_tx: "請填寫密碼",
  mima_cf: "驗證密碼",
  mima_cf_by: "密碼不一致",
  invite_code: "邀請碼",
  invite_code_tx: "請填寫邀請碼",
  mima_wj: "忘記密碼",
  old_password: "舊密碼",
  old_password_tx: "請填寫您的舊密碼",
  new_password: "新密碼",
  new_password_tx: "請填寫您的新密碼",
  repeat_password: "重複密碼",
  repeat_password_tx: "請重複密碼",
  mima_wj_zkf: "忘記密碼請聯絡客服",
  login_password: "登入密碼",
  withdrawal_password: "提現密碼",
  fanhui_zy: "返回首頁",
  goback_login: "返回登陸",
  hot_type: "熱門分類",
  hot_loan: "熱門貸款",
  city_card: "急速貸款",
  city_card_en: "City rewerd oredit oard",
  dianhua_lx: "聯絡電話",
  yingye_sj: "營業時間",
  meitian_sw: "每天上午",
  wanshang_z: "至晚上",
  lianxi_kf: "聯絡客服",
  yonghu_zh: "使用者帳號",
  about_us: "關於我們",
  dk_agreement: "貸款協議",
  exit_account: "登出帳號",
  yaoqing: "邀請碼",
  copy_ok: "複製成功",
  queren: "確認",
  jine_ktq: "可提現金額",
  yuan: "元",
  daikuan_jl: "貸款記錄",
  tuichu: "退出",
  yu_e: "餘額",
  daikuan_fl: "貸款分類",
  daikuan_lb: "貸款列表",
  lilv: "利率",
  tixian_zh: "提現帳戶",
  xingming_ckr: "持卡人姓名",
  xingming_ckr_tx: "請填寫持卡人姓名",
  yinhang_kh: "金融卡號",
  yinhang_srkh: "請輸入銀行卡號",
  tijiao: "提交",
  daikuan_je: "貸款金額",
  meiri_lx: "每月利息",
  daikuan_ed: "貸款額度",
  fukuan_fq: "分期付款",
  meiqi_fk: "每期付款",
  daikuan_fq: "貸款分期",
  daikuan_sq: "申請貸款",
  daikuan_jehlx: "貸款金額含利息",
  daikuan_tjq: "貸款提交期",
  daikuan_xinx: "貸款資訊",
  sign_q: "請在下面簽名",
  chongzhi: "重置",
  dengji: "登記",
  jiancha: "檢查",
  pingjia: "評價",
  tixian_cg: "提現成功",
  yue: "月",
  heyue_h: "合約號",
  loan_ts1:
    "您的貸款已提交評估。 24小時內有專人抽空檢查評估，跟進詢問客服進度。",
  tixian_kj_e: "可提現金額",
  tixian_je: "提現金額",
  tixian_mi: "提現密碼",
  loading: "請稍等",
  chong_zhi: "儲值",
  ky_yue: "可用餘額",
  bank_kh: "金融卡號",
  chongzhi_je: "儲值金額",
  qr_chongzhi: "確認儲值",
  max_money: "最高",
  min_money: "最低",
  max_je: "最大金額",
  min_je: "最小金額",
  qian: "฿",
  page_next: "下一頁",
  shenqing_dk: "申請貸款",
  qi: "期",
  lilv_yq: "逾期利率",
  fenqi: "分期",
  jine_gx: "金額過小",
  jine_gd: "金額過大",
  fenqi_xz: "請選擇分期",
  gengduo: "更多",
  shijian_jd: "借款時間",
  shijian_hk: "還款時間",
  yu_e_dj: "凍結餘額",
  user_info: "個人資訊",
  mima_jy: "交易密碼",
  mima_jy_cf: "重複交易密碼",
  mima_jy_by: "交易密碼不一致",
  touxiang_wk: "頭像不能為空",
  touxiang: "頭像",
  chongzhi_jt: "支付截圖",
  bank: "金融卡",
  zanwu_sj: "暫無數據",
  leixing: "帳戶類型",
  bank_tj: "新增帳戶",
  bank_mc: "銀行名稱",
  usdt_dz: "USDT地址",
  shenhe_w: "未審核",
  shenhe_g: "已通過",
  jujue: "已拒絕",
  shenhe_zt: "審核狀態",
  dingdan_h: "訂單編號",
  dakuan: "打款",
  dakuan_w: "未打款",
  dakuan_y: "已打款",
  huan_k: "還款",
  jinxing_z: "進行中",
  yi_wc: "已完成",
  tiqian_wc: "提前完成",
  yuqi: "逾期",
  zheng_c: "正常",
  yuqi_y: "已逾期",
  tishi: "提示",
  huan_k_bq: "本期應還",
  shenhe_zw:"暫無審核信息",
  shenhe_z:"審核中",
  hetong:"合同",
  qing_sd:"请稍等",
  yue_g:"个月"
}
