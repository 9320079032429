import { createStore } from "vuex";

export default createStore({
  state: {
    web_setting: {},
  },
  getters: {
    getWebSetting(state){
      return state.web_setting
    }
  },
  mutations: {
    updateWebSetting(state, payload) {
      state.web_setting = payload;
    },
  },
  actions: {
    updateWebSetting(context, payload) {
      context.commit("updateWebSetting", payload);
    },
  },
  modules: {},
});
