export const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register.vue"),
  },
  {
    path: "/load",
    name: "load",
    component: () => import("@/views/login/load.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home.vue"),
      },
      {
        path: "/record",
        name: "record",
        component: () => import("@/views/record/record.vue"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("@/views/order/order.vue"),
      },
      {
        path: "/service",
        name: "service",
        component: () => import("@/views/service/service.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/account.vue"),
      },
    ],
  },

  {
    path: "/hotCate",
    name: "hotCate",
    component: () => import("@/views/home/hotCate.vue"),
  },{
    path: "/goods",
    name: "goods",
    component: () => import("@/views/home/goods.vue"),
  },
  {
    path: "/recordDetails",
    name: "recordDetails",
    component: () => import("@/views/record/recordDetails.vue"),
  }, {
    path: "/contract",
    name: "contract",
    component: () => import("@/views/record/contract.vue"),
  },
  {
    path: "/review",
    name: "review",
    component: () => import("@/views/order/review.vue"),
  },{
    path: "/userInfo",
    name: "userInfo",
    component: () => import("@/views/cell/userInfo.vue"),
  },
  {
    path: "/passwordLogin",
    name: "passwordLogin",
    component: () => import("@/views/password/passwordLogin.vue"),
  },
  {
    path: "/passwordWithdraw",
    name: "passwordWithdraw",
    component: () => import("@/views/password/passwordWithdraw.vue"),
  },
  {
    path: "/bankCard",
    name: "bankCard",
    component: () => import("@/views/cell/bankCard.vue"),
  },{
    path: "/bankCardList",
    name: "bankCardList",
    component: () => import("@/views/cell/bankCardList.vue"),
  },
  {
    path: "/chongzhi",
    name: "chongzhi",
    component: () => import("@/views/cell/chongzhi.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("@/views/cell/withdraw.vue"),
  },{
    path: "/wallet",
    name: "wallet",
    component: () => import("@/views/cell/wallet.vue"),
  },{
    path: "/walletDetails",
    name: "walletDetails",
    component: () => import("@/views/cell/walletDetails.vue"),
  },{
    path: "/userList",
    name: "userList",
    component: () => import("@/views/cell/userList.vue"),
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/views/order/sign.vue"),
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/views/cell/protocol.vue"),
  },
  {
    path: "/myLoan",
    name: "myLoan",
    component: () => import("@/views/cell/myLoan.vue"),
  },
  {
    path: "/loan",
    name: "loan",
    component: () => import("@/views/loan/loan.vue"),
  },
  {
    path: "/repayLoan",
    name: "repayLoan",
    component: () => import("@/views/cell/repayLoan.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/cell/about.vue"),
  },
];
