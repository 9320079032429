module.exports = {
  login: "เข้าสู่ระบบ",
  register: "ลงทะเบียน",
  home: "หน้าแรก",
  record: "บันทึก",
  recordDetails: "บันทึกรายละเอียด",
  order: "คำสั่ง",
  service: "บริการลูกค้า",
  account: "ของฉัน",
  loan: "เงินกู้",
  protocol: "สัญญาเงินกู้",
  about: "เกี่ยวกับเรา",
  sign: "ลายเซ็นของผู้สมัคร",
  tixian: "ถอนเงินสด",
  apply_now: "ลงทะเบียน",
  username: "ชื่อผู้ใช้",
  username_tx: "กรุณากรอกชื่อผู้ใช้",
  phone: "เบอร์โทรศัพท์",
  phone_tx: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
  mima: "รหัสผ่าน",
  mima_tx: "กรุณากรอกรหัสผ่าน",
  mima_cf: "ยืนยันรหัสผ่าน",
  mima_cf_by: "รหัสผ่านไม่สอดคล้องกัน",
  invite_code: "รหัสการเชิญ",
  invite_code_tx: "กรุณากรอกรหัสเชิญ",
  mima_wj: "ลืมรหัสผ่าน",
  old_password: "รหัสผ่านเก่า",
  old_password_tx: "กรุณากรอกรหัสผ่านเก่าของคุณ",
  new_password: "รหัสผ่านใหม่",
  new_password_tx: "กรุณากรอกรหัสผ่านใหม่ของคุณ",
  repeat_password: "ทำรหัสผ่านซ้ำ",
  repeat_password_tx: "กรุณากรอกรหัสผ่านซ้ำ",
  mima_wj_zkf: "หากคุณลืมรหัสผ่าน โปรดติดต่อฝ่ายบริการลูกค้า",
  login_password: "รหัสผ่านการเข้าใช้งาน",
  withdrawal_password: "ถอนรหัสผ่าน",
  fanhui_zy: "กลับไปที่หน้าแรก",
  goback_login: "กลับสู่การเข้าสู่ระบบ",
  hot_type: "หมวดหมู่ยอดนิยม",
  hot_loan: "สินเชื่อยอดนิยม",
  city_card: "สินเชื่อด่วน",
  city_card_en: "City rewerd oredit oard",
  dianhua_lx: "เบอร์ติดต่อ",
  yingye_sj: "เวลาทำการ",
  meitian_sw: "ทุกเช้า",
  wanshang_z: "ถึงตอนเย็น",
  lianxi_kf: "ติดต่อฝ่ายบริการลูกค้า",
  yonghu_zh: "บัญชีผู้ใช้",
  about_us: "เกี่ยวกับเรา",
  dk_agreement: "สัญญาเงินกู้",
  exit_account: "ออกจากระบบ",
  yaoqing: "รหัสการเชิญ",
  copy_ok: "คัดสำเนาเรียบร้อย",
  queren: "ยืนยัน",
  jine_ktq: "จำนวนเงินสดที่มีอยู่",
  yuan: "บาท",
  daikuan_jl: "ประวัติสินเชื่อ",
  tuichu: "ล้มเลิก",
  yu_e: "สมดุล",
  daikuan_fl: "การจัดประเภทสินเชื่อ",
  daikuan_lb: "รายการเงินกู้",
  lilv: "ดอกเบี้ย",
  tixian_zh: "ถอนบัญชี",
  xingming_ckr: "ชื่อผู้ถือบัตร",
  xingming_ckr_tx: "กรุณากรอกชื่อผู้ถือบัตร",
  yinhang_kh: "หมายเลขบัตรทางการเงิน",
  yinhang_srkh: "กรุณากรอกหมายเลขบัตรธนาคาร",
  tijiao: "ยืนยัน",
  daikuan_je: "จำนวนเงินกู้",
  meiri_lx: "ดอกเบี้ยรายเดือน",
  daikuan_ed: "จำนวนเงินกู้",
  fukuan_fq: "ค่างวด",
  meiqi_fk: "ผ่อนชำระต่องวด",
  daikuan_fq: "ผ่อนชำระสินเชื่อ",
  daikuan_sq: "สมัครสินเชื่อ",
  daikuan_jehlx: "จำนวนเงินกู้รวมดอกเบี้ยแล้ว",
  daikuan_tjq: "ระยะเวลายื่นกู้",
  daikuan_xinx: "ข้อมูลสินเชื่อ",
  sign_q: "กรุณาลงชื่อด้านล่าง",
  chongzhi: "รีเซ็ต",
  dengji: "ลงทะเบียน",
  jiancha: "ตรวจสอบ",
  pingjia: "การประเมิน",
  tixian_cg: "ถอนเงินสำเร็จ",
  yue: "เดือน",
  heyue_h: "เลขที่สัญญา",
  loan_ts1:
    "เงินกู้ของคุณถูกส่งไปเพื่อการประเมินแล้ว。เจ้าหน้าที่เฉพาะด้านจะพร้อมสำหรับการตรวจสอบและประเมินผลภายใน 24 ชั่วโมง，ติดตามและสอบถามความคืบหน้าการบริการลูกค้า。",
  tixian_kj_e: "จำนวนเงินสดที่มีอยู่",
  tixian_je: "ถอนจำนวนเงิน",
  tixian_mi: "ถอนรหัสผ่าน",
  loading: "โปรดรอ",
  chong_zhi: "มูลค่าที่เก็บไว้",
  ky_yue: "ยอดเงินคงเหลือ",
  bank_kh: "หมายเลขบัตรทางการเงิน",
  chongzhi_je: "จำนวนมูลค่าที่เก็บไว้",
  qr_chongzhi: "ยืนยันมูลค่าที่เก็บไว้",
  max_money: "สูงสุด",
  min_money: "ต่ำสุด",
  max_je: "กู้สูงสุด",
  min_je: "กู้ขั้นต่ำ",
  qian: "฿",
  page_next: "หน้าต่อไป",
  shenqing_dk: "สมัครสินเชื่อ",
  qi: "ค่างวด",
  lilv_yq: "ดอกเบี้ยที่ค้างชำระ",
  fenqi: "ค่างวด",
  jine_gx: "จำนวนเงินน้อยเกินไป",
  jine_gd: "จำนวนเงินมากเกินไป",
  fenqi_xz: "กรุณาเลือกการผ่อนชำระ",
  gengduo: "มากกว่า",
  shijian_jd: "เวลายืม",
  shijian_hk: "ระยะเวลาการชำระคืน",
  yu_e_dj: "ตรึงความสมดุล",
  user_info: "ข้อมูลส่วนบุคคล",
  mima_jy: "รหัสผ่านการทำธุรกรรม",
  mima_jy_cf: "ทำซ้ำรหัสผ่านการทำธุรกรรม",
  mima_jy_by: "รหัสผ่านการทำธุรกรรมไม่สอดคล้องกัน",
  touxiang_wk: "สัญลักษณ์ไม่สามารถเว้นว่างได้",
  touxiang: "สัญลักษณ์",
  chongzhi_jt: "ภาพหน้าจอการชำระเงิน",
  bank: "บัตรเดบิต",
  zanwu_sj: "ยังไม่มีข้อมูล",
  leixing: "ประเภทบัญชี",
  bank_tj: "เพิ่มบัญชีใหม่",
  bank_mc: "ชื่อธนาคาร",
  usdt_dz: "ที่อยู่ USDT",
  shenhe_w: "ไม่ได้รับการตรวจทาน",
  shenhe_g: "อนุมัติผ่าน",
  jujue: "ถูกปฏิเสธ",
  shenhe_zt: "ตรวจสอบสถานะ",
  dingdan_h: "หมายเลขคำสั่งซื้อ",
  dakuan: "ชำระเงิน",
  dakuan_w: "ไม่มีการชำระเงินใดๆ",
  dakuan_y: "ชำระเงินเรียบร้อยแล้ว",
  huan_k: "การชำระคืน",
  jinxing_z: "กำลังดำเนินการ",
  yi_wc: "สมบูรณ์",
  tiqian_wc: "เสร็จก่อนกำหนด",
  yuqi: "ค้างชำระ",
  zheng_c: "ปกติ",
  yuqi_y: "ค้างชำระ",
  tishi: "ยืนยัน",
  huan_k_bq: "เนื่องจากช่วงนี้",
  shenhe_zw:"ยังไม่มีข้อมูลรีวิว",
  shenhe_z:"ภายใต้การทบทวน",
  hetong:"สัญญา",
  qing_sd:"โปรดรอ",
  yue_g:"เดือน"
}
