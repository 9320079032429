import { createRouter, createWebHashHistory } from 'vue-router'
import {routes} from './router'

const router = createRouter({
  // base: process.env.NODE_ENV === "production" ? "/h5/" : "/",
  history: createWebHashHistory(),
  routes
})

export default router
