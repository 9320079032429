<template>
  <nav-bar></nav-bar>
  <router-view />
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex'

import navBar from '@/components/navBar.vue'
import { setting } from '@/api/api'

import {onLang} from '@/utils/toLang'
// onLang()

const store = useStore()

const getSetting = async () => {
  let {data} = await setting()
  store.dispatch('updateWebSetting', data)
}
onBeforeMount(()=>{
  getSetting()
})
</script>

<style lang="scss">
@import '@/assets/iconfont/iconfont.css';
@import '@/assets/css/common.scss';
</style>
