import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import 'vant/lib/index.css';

import {i18n} from '@/locale/index'
import { NavBar,Form, Field,Picker, CellGroup,Button,Icon,Tabbar,TabbarItem,Cell,Uploader,Popup,Step,Steps,NoticeBar,Swipe,SwipeItem,Slider} from 'vant';
createApp(App).use(store).use(router).use(i18n).use(Icon).use(Tabbar).use(TabbarItem).use(Cell).use(Uploader).use(Popup).use(Step).use(Steps).use(NoticeBar).use(Swipe).use(SwipeItem).use(Slider)
.use(NavBar).use(Form).use(Field).use(CellGroup).use(Button).use(Picker ).
mount('#app')
