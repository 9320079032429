import { service } from "@/api/request";
import qs from "qs";

const http = service;

// 注册
export const toRegister = (params) => {
  return http.post("api/reg", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 登录
export const toLogin = (params) => {
  return http.post("api/login", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 记录
export const record = (params) => {
  return http.get("api/user/product/index", { params }).then((result) => {
    return result.data;
  });
};

// 单页数据
export const pageData = (params) => {
  return http.get("api/page", {params}).then((result) => {
    return result.data;
  });
};

// 轮播
export const banner = (params) => {
  return http.get("api/banner", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 网站基础数据
export const setting = (params) => {
  return http.get("api/setting", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 分类列表
export const cateList = (params) => {
  return http
    .post("api/product/cate/list", qs.stringify(params))
    .then((result) => {
      return result.data;
    });
};

// 商品列表
export const productList = (params) => {
  return http.post("api/product/list", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 商品详情
export const productInfo = (params) => {
  return http.post("api/product/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 分类详情
export const cateInfo = (params) => {
  return http
    .post("api/product/cate/info", qs.stringify(params))
    .then((result) => {
      return result.data;
    });
};

// 审核信息详情
export const auditInfo = (params) => {
  return http.get("api/user/product/audit", { params }).then((result) => {
    return result.data;
  });
};

// 上传图片
export const upfile = (file) => {
  const formData = new FormData();
  formData.append("image", file.file);
  return http
    .post("api/upload/image", formData, {
      headers: {
        // 表示上传的是文件,而不是普通的表单数据
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      return result.data;
    });
};

// 提交审核信息
export const applyAudit = (params) => {
  return http
    .post("api/user/product/audit/apply", qs.stringify(params))
    .then((result) => {
      return result.data;
    });
};

// 申请商品
export const applyProduct = (params) => {
  return http
    .post("api/user/product/apply", qs.stringify(params))
    .then((result) => {
      return result.data;
    });
};

// 获取用户数据
export const getUserInfo = (params) => {
  return http.post("api/user/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 修改用户数据
export const updateInfo = (params) => {
  return http.post("api/update/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 充值
export const recharge = (params) => {
  return http.post("api/user/recharge", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 充值列表
export const rechargeList = (params) => {
  return http.post("api/user/recharge/list", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 获取平台充值地址
export const getBank = (params) => {
  return http.get("api/bank", {params}).then((result) => {
    return result.data;
  });
};

// 获取用户地址
export const getaddress = (params) => {
  return http.post("api/user/draw/getaddress", qs.stringify(params)).then((result) => {
    return result.data;
  });
};

// 添加用户地址
export const addAddress = (params) => {
  return http.post("api/user/draw/address", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 提现
export const draw = (params) => {
  return http.post("api/user/draw", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 提现列表
export const drawList = (params) => {
  return http.post("api/user/draw/list", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 充值列表
export const getRechargeList = (params) => {
  return http.post("api/user/recharge/list", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 记录详情
export const recordDetails = (params) => {
  return http.get("api/user/product/detail", {params}).then((result) => {
    return result.data;
  });
}

// 還款详情
export const repayInfo = (params) => {
  return http.post("api/user/product/repay/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
}

// 還款
export const repayPay = (params) => {
  return http.post("api/user/product/repay/pay", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
// 合同
export const certInfo = (params) => {
  return http.post("api/product/cert/info", qs.stringify(params)).then((result) => {
    return result.data;
  });
}
