<template>
  <div class="nav-bar">
    <van-nav-bar
      v-if="nav_info.title"
      :title="nav_info.title"
      :left-arrow="!nav_info.is_back"
      :fixed="!nav_info.fixed"
      :placeholder="!nav_info.placeholder"
      @click-left="onClickLeft"
      :border="false"
    >
      <template #right v-if="nav_info.right == 'news'">
        <i class="iconfont">&#xe63a;</i>
      </template>
    </van-nav-bar>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
let router = useRouter()
const { t } = useI18n()
const nav_config = computed(()=>{
  return {
  /* login:{
            title:t('login'),
            fixed:true,
            is_back:true,
            placeholder:true
        }, */
  order: {
    title: t('order'),
    is_back: true,
  },
  wallet:{
    title: t('huan_k')
  },
  walletDetails:{
    title:t('huan_k')
  },
  review:{
    title: t('shenhe_zt')
  },
  service: {
    title: t('service'),
    is_back: true,
  },
  account: {
    title: t('account'),
    is_back: true,
  },
  passwordLogin: {
    title: t('login_password'),
  },
  passwordWithdraw: {
    title: t('withdrawal_password'),
  },
  myProfile: {
    title: t('myProfile'),
  },
  identity: {
    title: t('identity'),
  },
  recordDetails: {
    title: t('recordDetails'),
  },
  record: {
    title: t('record'),
    is_back: true,
  },
  contract:{
    title: t('hetong')
  },
  withdraw: {
    title: t('tixian'),
  },
  bankCard: {
    title: t('tixian_zh'),
  },
  bankCardList:{
    title: t('tixian_zh'),
  },
  sign: {
    title: t('sign'),
  },
  protocol: {
    title: t('protocol'),
  },
  myLoan: {
    title: t('myLoan'),
  },
  loan: {
    title: t('loan'),
  },
  repayLoan: {
    title: t('repayLoan'),
  },
  about: {
    title: t('about'),
  },
  chongzhi: {
    title: t('chong_zhi'),
  },
  hotCate:{
    title:t('hot_type')
  },
  goods:{
    title:t('hot_loan')
  },
  userInfo:{
    title:t('user_info')
  }
}
})
const nav_info = computed(() => {
  return nav_config.value[router.currentRoute.value.name] || {}
})
const onClickLeft = () => history.back()
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
::v-deep {
  .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: $theme1;
  }
  .van-nav-bar__title {
    color: #fff;
  }
}
</style>
