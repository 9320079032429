import { createI18n } from 'vue-i18n' //引入vue-i18n组件

export const messages={
    // 'zh_CN':require('./lang/cn'),
    'th':require('./lang/th')
}
export const lang_list={
    // 'zh_CN':'中文',
    'th':'แบบไทย'
}
export const langDefault=()=>{
    lang='th'
    localStorage.setItem('lang',lang)
}
let lang = localStorage.getItem('lang')
if(!lang||!messages[lang]){
    langDefault()
}

export const i18n = createI18n({
    globalInjection:true,
    legacy: false,
    locale: lang,
    messages
  })