import axios from "axios";
import { showLoadingToast, closeToast } from "vant";
import router from '@/router'
import {i18n} from '@/locale/index';
const { t } = i18n.global;
import {langDefault} from '@/locale/index'

const baseURL='https://dk.thaialiexpress.com/'  //正式

// 创建一个 axios 实例
const service = axios.create({
  baseURL:baseURL, // 所有的请求地址前缀部分
  // baseURL: '/api', // 所有的请求地址前缀部分
  timeout: 100000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
})

// 登录拦截白名单
const while_router=['/login','/register']
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("api_token")
    if (token) {
      config.headers["Api-token"] = token;
    }else{
      const currentRoute=router.currentRoute.value.fullPath,
      is_login=while_router.indexOf(currentRoute)
      if(is_login==-1){
        router.push('/login')
      }
    }
    var lang = localStorage.getItem("lang");
    if(lang){
			config.params = {
				...config.params,
				Language:lang
			  }
			config.headers['Language'] = lang
		}
    showLoadingToast({
      message: t("loading") + "...",
      forbidClick: true,
    });
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    closeToast();
    if(response.data.code==99){
      localStorage.clear()
      langDefault()
      router.push('login')
    }
    return response;
  },
  (error) => {
    closeToast(); //清除加载
    return Promise.reject(error);
  }
);
export { service,baseURL }
